export default {
  name: "DomainAdd",
  data: () => ({
    domain: {
      name: undefined
    },
    isDomainNameDuplicate: false,
    showAddDomainDialog  : false
  }),
  props: {
    pIsAddingDomain: Boolean,
    pDomainAddError: Object
  },
  methods: {
    addDomain() {
      this.$emit("addDomain", {
        ...this.domain
      })
    }
  },
  watch: {
    "domain.name": {
      handler: function() {
        if (this.isDomainNameDuplicate) {
          this.$emit("resetDomainAddError")
        }
      }
    },
    pDomainAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isDomainNameDuplicate = true
        } else {
          this.isDomainNameDuplicate = false
        }
      }
    }
  }
}